import Layout from "../components/layout";
import SEO from "../components/seo";
import StyledButton from "../components/styledButton";
import { Link } from "gatsby";
import React from "react";

function Success({ location }) {
  const message =
    location?.state?.message ||
    "Thanks for reaching out. We'll be in touch soon.";
  return (
    <>
      <Layout>
        <SEO title="Thank you" />
        <div className="h-screen">
          <div className="my-auto flex flex-col items-center align-center justify-center">
            <h1 className="text-5xl">Success</h1>
            <p className="mb-2">{message}</p>
            <Link to="/">
              <StyledButton>Home</StyledButton>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Success;
